import { Input, InputRef, Popover } from 'antd';
import { useEffect, useRef, useState, useContext } from 'react';
import { CorrectionsPopUp } from 'shared/components/ui/editor/editor/CorrectionsPopUp';
import { PShard } from 'shared/components/ui/editor/editor/CorrectionsPopUp.styled';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { GateWaySockets, CaptionShard } from 'shared/hooks/axon/gateway.types';

const LINE_BREAK = '\n'

export const ShardParagraph = ({
  shardText,
  shardId,
  alternatives,
  editable,
  shardWords
}: CaptionShard) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [text, setText] = useState<string>(shardText);
  const [isVisible, setIsVisible] = useState(false);
  const [isOnCorrection, setIsOnCorrection] = useState(false);
  const { 
    sendShardCorrection,
  } = useContext(IPCTSSessionContext);

  const [inputWidth, setInputWidth] = useState<string>('auto');
  const pRef = useRef<HTMLParagraphElement | null>(null);
  const inputRef = useRef<InputRef | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLParagraphElement>) => {
    if (editable && !isEditing) {
      setIsEditing(true);
      setTimeout(() => {
        const input = inputRef.current?.input; // Access the native input element
        if (input) {
          const rect = input.getBoundingClientRect();
          const mouseX = event.clientX;
          const offsetX = mouseX - rect.left;
          const textLength = text.length;

          let charIndex = 0;
          for (let i = 0; i < textLength; i++) {
            const characterWidth = input.scrollWidth / textLength;
            if (offsetX < characterWidth * (i + 1)) {
              charIndex = i;
              break;
            }
          }

          input.setSelectionRange(charIndex, charIndex);
          input.focus();
        }
      }, 0);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    updateInputWidth(event.target.value);
  };

  const blurShardInput = (event: any) => {
    if(isEditing){
      sendShardCorrection(GateWaySockets.captionerSocket, shardId, event.target.value)
      setIsEditing(false);
    }
  };
  const handleInputKeyup = (event: any) => {
    if(event.key === 'Enter'){
      if(event.ctrlKey){
        if(shardWords.length){
          const lastWord = shardWords[shardWords.length-1];
          setIsEditing(false);
          const cursorIndex = inputRef.current?.input?.selectionStart || 0;
          const shardStart = text.substring(0, cursorIndex)
          const shardEnd = text.substring(cursorIndex)
          const newText = shardStart + LINE_BREAK + shardEnd
          sendShardCorrection(GateWaySockets.captionerSocket, shardId, newText)
          return;
        }
      }
      event.currentTarget.blur();
    }
  }

  useEffect(() => {
    setText(shardText);
    updateInputWidth(shardText);
  }, [shardText]);

  const updateInputWidth = (value: string) => {
    if (pRef.current) {
      pRef.current.innerText = value || '';
      const newWidth = pRef.current.offsetWidth;
      setInputWidth(`${newWidth}px`);
    }
  };

  // return (
  //   <Popover
  //     key={`shard-${shardId}-popup`}
  //     id={`shard-${shardId}-popup`}
  //     content={
  //       <CorrectionsPopUp
  //         shardId={shardId}
  //         text={text.replace(/^[\W_]+|[\W_]+$/g, '').trim()}
  //         alternatives={alternatives}
  //         onHide={() => setIsVisible(false)}
  //         isOpen={isVisible}
  //         setIsOnCorrection={setIsOnCorrection}
  //       />
  //     }
  //     trigger={'click'}
  //     visible={isVisible}
  //     onVisibleChange={() => setIsVisible(!isVisible)}
  //   >
  //     <PShard
  //       isOnCorrection={isOnCorrection}
  //       key={`shard-${shardId}`}
  //       id={`shard-${shardId}`}
  //       data-alternatives={JSON.stringify(alternatives)}
  //       className="item"
  //       data-id={shardId.toString()}
  //       onCopy={(e) => {
  //         e.preventDefault();
  //         navigator.clipboard.writeText('');
  //       }}
  //       onCut={(e) => {
  //         e.preventDefault();
  //         navigator.clipboard.writeText('');
  //       }}
  //       onPaste={(e) => false}
  //       onDragStart={(e) => false}
  //       data-text={text}
  //     >
  //       {text}
  //     </PShard>
  //   </Popover>
  // );

  return (
    <>
      <PShard
        isOnCorrection={isOnCorrection}
        ref={pRef}
        style={{
          visibility: 'hidden',
          whiteSpace: 'pre',
          position: 'absolute',
        }}
      />
      {isEditing ? (
        <Input
          ref={inputRef}
          value={text}
          onChange={handleChange}
          onBlur={blurShardInput}
          onKeyUp={handleInputKeyup}
          autoFocus
          variant="borderless"
          data-id-input={shardId.toString()}
          style={{
            width: inputWidth,
            padding: '4px 0px',
          }}
        />
      ) : (
        <PShard
          isOnCorrection={isOnCorrection}
          key={`shard-${shardId}`}
          id={`shard-${shardId}`}
          data-alternatives={JSON.stringify(alternatives)}
          className="item"
          data-id={shardId.toString()}
          onCopy={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText('');
          }}
          onCut={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText('');
          }}
          onPaste={(e) => false}
          onDragStart={(e) => false}
          data-text={text}
          onClick={handleClick}
        >
          {renderLineBreakText(text)}
        </PShard>
      )}
    </>
  );
};

function renderLineBreakText(text: string){
  if(text.includes(LINE_BREAK)){
    const displayText = text;
    return (
      <>
        {displayText}
        <br />
      </>
    )
  }
  return (
    <>
      {text}
    </>
  )
}