import { AuthProvider } from 'modules/authentication/msal-provider';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import theme from 'shared/styles/theme';
import { ThemeProvider } from 'styled-components';
import App from './App';
import 'antd/dist/reset.css';
import './fonts.css';
import 'shared/config/rest.config';
import 'shared/config/dates.config';
import 'shared/config/pendo.config';

document.execCommand('defaultParagraphSeparator', false, 'p');

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>
);
