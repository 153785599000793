import { notification } from 'antd';
import { HANDOFFS } from 'modules/ipcts-call-session/components/controls/context/call-handoff.types';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { IpctsSession } from 'modules/ipcts-call-session/ipcts-session.component';
import PreflightChekScreen from 'modules/ipcts-call-session/pfc-screen/preflight-check.component';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import * as React from 'react';
import HandoffScreen from 'shared/components/ui/handoff-screen/handoff-screen.component';
import QueueScreen from 'shared/components/ui/queue-screen/queue-screen.component';
import { ECallStateCode } from 'shared/datasources/call-detail-record-service/entities.dto';
import { CallState } from 'shared/datasources/session-state-service/entities.dto';
import { GateWaySockets } from 'shared/hooks/axon/gateway.types';

import useAudio from 'shared/hooks/use-audio.hook';

export const LandingPage = () => {
  const {
    setReadyForCall,
    isCallActive,
    setIsCallActive,
    gracefulDisconnectFlow,
    dispositionSelected,
    setDispositionSelected,
    setSilentDispositionSelected,
    setCorrectionsCounter,
    // registerAgent,
    // unregisterAgent,
    requestedHandoff,
    setRequestedHandoff,
    setShowQueue,
    callState,
    setCallStateCode,
    setIsSessionScreen,
    showPFC,
    setShowPFC,
  } = React.useContext(LandingPageContext);


  const {
    text,
    isActiveSession,
    isTokenReady,
    cardCaptionCallRef,
    handleStopListening,
    handleChange,
    handleStartListening,
    reset,
    addCustomCaption,
    correctionsCounter,
    setActiveGateway,
  } = React.useContext(IPCTSSessionContext);

  const { play } = useAudio('/audios/beep.mp3');
  const [isHandoff, setIsHandoff] = React.useState(false);

  React.useEffect(() => {
    window.onbeforeunload = () => '';
    // registerAgent();
    return () => {
      setReadyForCall(false);
      gracefulDisconnectFlow();
      if (!window.electronAPI) {
        window.onbeforeunload = null;
      }
      // unregisterAgent();
    };
  }, []);

  React.useEffect(() => {
    if (!isCallActive) {
      handleStopListening(GateWaySockets.captionerSocket);
    }
  }, [isCallActive, handleStopListening]);

  React.useEffect(() => {
    if (correctionsCounter === 0) return;
    setCorrectionsCounter(correctionsCounter);
  }, [correctionsCounter]);

  const dispositionCallEnd = () => {
    reset();
    setIsCallActive(false);
  };

  const handoffCallEnd = () => {
    dispositionCallEnd();
    setIsHandoff(true);
  };

  React.useEffect(() => {
    if (isHandoff) {
      setReadyForCall(false);
      gracefulDisconnectFlow();
    }
  }, [isHandoff]);

  React.useEffect(() => {
    if (callState === CallState.OneOnCallEnd) {
      setCallStateCode(ECallStateCode.InPrgWMi);
      notification.info({
        message: 'One one call end event',
        description: 'The call will end in 15 seconds.',
        placement: 'topRight',
        duration: 10,
      });
    }
  }, [callState]);

  const handleShowLogOutButtonchange = (showLogOutButton: boolean) => {
    if (showLogOutButton) {
      document.querySelector('#logoutButton')?.removeAttribute('hidden');
      return;
    }
    document.querySelector('#logoutButton')?.setAttribute('hidden', 'true');
  };

  //Functions for testing purposes
  (window as any).startcall = () => {
    play();
    setIsCallActive(true);
  };

  (window as any).endcall = () => {
    dispositionCallEnd();
  };

  (window as any).addSilenceCaption = () => {
    addCustomCaption('(Silence)');
  };

  (window as any).triggerHandoff = (language: boolean = false) => {
    if (language) {
      setRequestedHandoff(HANDOFFS.ENGLISH_SPANISH.LABEL);
    }
    dispositionCallEnd();
    setIsHandoff(true);
  };

  const showScreen = () => {
    handleShowLogOutButtonchange(false);
    if (showPFC) {
      handleShowLogOutButtonchange(true);
      setActiveGateway(GateWaySockets.PFCSocket)
      return (
        <PreflightChekScreen
          setShowQueue={setShowQueue}
          setShowPFC={setShowPFC}
          setIsSessionScreen={setIsSessionScreen}
          text={text}
          handleChange={handleChange}
          handleStartListening={handleStartListening}
          handleStopListening={handleStopListening}
          cardCaptionCallRef={cardCaptionCallRef!}
        />
      );
    }
    if (isCallActive) {
      setActiveGateway(GateWaySockets.captionerSocket)
      return (
        <IpctsSession
          text={text}
          isActiveSession={isActiveSession}
          isTokenReady={isTokenReady}
          cardCaptionCallRef={cardCaptionCallRef}
          handleChange={handleChange}
          handleStartListening={handleStartListening}
          setIsCallActive={setIsCallActive}
          dispositionCallEnd={dispositionCallEnd}
          handoffCallEnd={handoffCallEnd}
          addCustomCaption={addCustomCaption}
          dispositionSelected={dispositionSelected}
          setDispositionSelected={setDispositionSelected}
          setSilentDispositionSelected={setSilentDispositionSelected}
        />
      );
    }
    if (!isHandoff) {
      return <QueueScreen />;
    }

    if (requestedHandoff === HANDOFFS.ENGLISH_SPANISH.LABEL) {
      return <HandoffScreen isLanguageHandOff />; //goes to queue again
    }

    return <HandoffScreen />;
  };

  return <>{showScreen()}</>;
};
