import { Layout } from 'antd';
import { CurrentRouteContext } from 'modules/routing/context/current-route.context';
import { ComponentType } from 'modules/routing/routes.types';

import * as React from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
  PathMatch,
} from 'react-router-dom';

import * as Styled from './route.component.styled';

interface RouteComponentPropsType {
  component: ComponentType;
  match?: boolean | PathMatch<string> | null;
}

const RouteComponent: React.FC<RouteComponentPropsType> = ({
  component: Component,
  match,
}) => {
  const [currentRoute] = React.useContext(CurrentRouteContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const layout = React.useMemo(
    () => (
      <Styled.RootLayout>
        <Layout>
          <Component {...{ navigate, location, params, match }} />{' '}
        </Layout>
      </Styled.RootLayout>
    ),
    [navigate, location, params, match, Component]
  );

  const body = React.useMemo(() => {
    const providedLayout = currentRoute.providers || [];
    return providedLayout.reduceRight(
      (children, Provider) => <Provider>{children}</Provider>,
      layout
    );
  }, [currentRoute.providers, layout]);

  return body;
};

export default RouteComponent;
