import { AxiosPromise } from 'axios';
import { RefetchOptions } from 'axios-hooks';

export enum Entity {
  Agent = '/agent',
  AllAgents = '/all-agents',
  AddAgent = '/add-agent',
  AddAgentSkills = '/add-agent-skills',
  RemoveAgent = '/remove-agent',
  ModifyAgent = '/modify-agent-state',
}

export type Agent = {
  active_directory_id: string;
  notification_id: string;
  firstname: string;
  lastname: string;
  username: string;
  hostipaddress: string;
  sip_address: string;
  sip_port: string;
  rtp_port: string;
  transparency_service_port: string;
};

export type AgentSkills = {
  agentId: string;
  skills: string[];
};

export type AgentState = {
  id: string;
  notification_id: string;
  state: string;
  hostipaddress: string;
  skills?: string[];
  sip_address: string;
  sip_port: string;
  rtp_port: string;
  transparency_service_port: string;
};

export type GetAgentResponse = {
  UsrID: number;
  ActiveDirectoryID: number;
  SipAddress: string;
};

export type NotificationService = {
  AgentId: string;
  NotificationId?: string;
  Skills: string[];
  HostIpAddress: string;
  SipAddress: string;
  CreatedDt: string;
  ActiveDirectoryID: string;
  IsVCA: boolean;
};

export type ReturnType = {
  [Entity.Agent]: any;
  [Entity.AllAgents]: any;
  [Entity.AddAgent]: any;
  [Entity.AddAgentSkills]: any;
  [Entity.RemoveAgent]: any;
  [Entity.ModifyAgent]: any;
};

export type HookValue<T extends Entity> = [
  (
    config?: any,
    options?: RefetchOptions | undefined
  ) => AxiosPromise<ReturnType[T]>,
  [ReturnType[T] | undefined, boolean, any]
];

export type AgentPFCResult = {
  agent_id: string;
  date: string;
  mic_pass: boolean;
  sound_pass: boolean;
  network_pass: boolean;
  score: number;
  suitable_for_calls: boolean;
};
