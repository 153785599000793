import RouteComponent from 'modules/routing/components/route/route.component';
import { CurrentRouteContext } from 'modules/routing/context/current-route.context';
import * as React from 'react';
import { ILinkedRoute } from '../../routes.types';
import { useMatch } from 'react-router-dom';

export interface IAuthRoute {
  linkedRoute: ILinkedRoute;
}

const AuthRoute: React.FC<IAuthRoute> = ({ linkedRoute }) => {
  const { component, path, showNavBar, showSideBar, providers } = linkedRoute;

  const [, setCurrentRoute] = React.useContext(CurrentRouteContext);

  const match = Array.isArray(path)
    ? path.some((p) => useMatch(p))
    : useMatch(path);

  React.useEffect(() => {
    setCurrentRoute({ path, showNavBar, showSideBar, providers });
  }, [linkedRoute, path, showNavBar, showSideBar, providers, setCurrentRoute]);

  return <RouteComponent component={component} match={match} />;
};

export default React.memo(AuthRoute);
