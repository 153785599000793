/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { makeUseAxios, UseAxios } from 'axios-hooks';
import { config } from 'shared/config/cts.config';

let accessToken: string;

export function setAccessToken(token: string) {
  accessToken = token;
}

const requestInterceptor = async (config: InternalAxiosRequestConfig) => {
  if (accessToken && config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

const makeAxios = (baseURL: string, hook: boolean, addInterceptor: boolean) => {
  const instance = axios.create({ baseURL });
  if (addInterceptor) {
    instance.interceptors.request.use(requestInterceptor);
  }
  return hook
    ? makeUseAxios({
        axios: instance,
      })
    : instance;
};

const caasService = makeAxios(
  `${config.REACT_APP_API_REST_URL}`,
  true,
  true
) as UseAxios;

const authService = makeAxios(
  `${config.REACT_APP_AUTH_API}`,
  false,
  true
) as AxiosInstance;

const useAuthService = makeAxios(
  `${config.REACT_APP_AUTH_API}`,
  true,
  true
) as UseAxios;

const agentService = makeAxios(
  `${config.REACT_APP_AGENTS_SERVICE_API_REST_URL}`,
  true,
  false
) as UseAxios;

const agentQueueService = makeAxios(
  `${config.REACT_APP_API_REST_URL_AQ}`,
  true,
  false
) as UseAxios;

const handoffService = makeAxios(
  `${config.REACT_APP_HANDOFF_SERVICE}`,
  true,
  false
) as UseAxios;

const useConfigService = makeAxios(
  `${config.REACT_APP_CONFIG_SERVICE}`,
  true,
  false
) as UseAxios;

export {
  caasService,
  authService,
  agentService,
  agentQueueService,
  handoffService,
  useAuthService,
  useConfigService,
};
