import Headphones from 'assets/icons/headphones.svg';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';

import * as React from 'react';

import * as Styled from './bottom-controls.component.styled';

interface BottonControlsInterface {}

const BottomControls = ({}: BottonControlsInterface) => {
  const [inputValue, setInputValue] = React.useState(1.0);
  const { jssipAudioRef } = React.useContext(LandingPageContext);
  const handleVolumeChange = React.useCallback(
    (value: number) => {
      setInputValue(value);
      if (jssipAudioRef) {
        /* eslint-disable no-param-reassign */
        jssipAudioRef.volume = value;
      }
    },
    [jssipAudioRef]
  );

  return (
    <Styled.ControlsWrapper>
      <Styled.VolumeWrapper>
        <Styled.HeadphoneIcon src={Headphones} />
        <Styled.SliderContainer id="audioSliderContainer">
          <Styled.AudioSlider
            id="volumeSlider"
            min={0}
            max={1}
            step={0.1}
            onChange={handleVolumeChange}
            value={inputValue}
          />
        </Styled.SliderContainer>
      </Styled.VolumeWrapper>
    </Styled.ControlsWrapper>
  );
};

export default BottomControls;
