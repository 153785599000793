import NotFoundSVG from 'assets/icons/404.svg';
import applicationRoutes from 'modules/routing/routes';
import SVG from 'react-inlinesvg';
import { Button } from 'shared/components/ui/button.component';

import * as Styled from './not-found.styled';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(applicationRoutes.ROOT);
  };
  return (
    <Styled.Container>
      <SVG src={NotFoundSVG} />
      <Styled.Code>404</Styled.Code>
      <Styled.Message>
        Sorry, The Page You Visited Does Not Exist.
      </Styled.Message>
      <Button onClick={handleClick}>Back to Home</Button>
    </Styled.Container>
  );
};
export default NotFound;
