import { Typography } from 'antd';

import { CardsContainer } from 'shared/components/ui/card-container.styled';
import styled from 'styled-components';

type CheckDisabled = {
  isDisabled?: boolean;
  fontSize: number;
  lineHeight: number;
  fontFamily: string;
};

export const CardSessionContainer = styled(CardsContainer)`
  flex: auto;
  padding: 24px 28px 28px 28px;
  background: ${({ theme }) => theme.colors.sessionBackground};
  border-top: 1px solid rgba(255, 255, 255, 0.21);
  border-right: 1px solid rgba(255, 255, 255, 0.21);
  border-left: 1px solid rgba(255, 255, 255, 0.21);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  height: 87vh;
  .vertical-default-scroll {
    background: ${({ theme }) => theme.colors.sessionBackground};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 62%;
  font-family: ${({ theme }) => theme.fonts.inter_bold};
  font-size: ${({ theme }) => theme.fontSizes['3xl']};
  margin: 20px 0 15px 0;
  .ant-skeleton {
    .ant-skeleton-content {
      h3 {
        height: 28px !important;
        margin: 0;
      }
    }
  }
`;

export const ToggleContainer = styled.div`
  height: 48px;
  width: 324px;
  display: flex;
  border-radius: 6px;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakPoints.md}) {
    display: inline-flex;
  }
`;

export const Chronometer = styled.div`
  color: ${({ theme }) => theme.colors.primaryColorBase};
  margin-bottom: 9.5px;
  font-size: ${({ theme }) => theme.fontSizes['6xl']};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  text-align: center;
`;

export const DataContainer = styled.div`
  padding: 0px 24px;
  display: flex;
  flex: auto;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  @media (max-width: ${({ theme }) => theme.breakPoints.lg}) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2 1 auto;
  margin: -35px 17px 0 0;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 457px;
  min-width: 457px;
  max-width: 457px;
  @media (max-width: ${(props) => props.theme.breakPoints.xl}) {
    width: 97.15%;
  }
`;

export const Title = styled(Typography.Title)`
  font-size: ${({ theme }) => theme.fontSizes['2xl']} !important;
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium} !important;
  font-weight: ${({ theme }) => theme.fontWeights.medium} !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.fontColor} !important;
  margin-left: 8px;

  h1.ant-typography,
  .ant-typography h1 {
    margin-bottom: 0px;
  }
  .ant-typography-expand {
    color: ${({ theme }) => theme.colors.activeState};
  }
  .ant-typography a:hover,
  .ant-typography-expand:hover {
    color: ${({ theme }) =>
      theme.colors.activeStateRGBA?.replace('{opacity}', '0.6')};
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
    font-size: ${({ theme }) => theme.fontSizes['2xl']};
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
`;

export const CardCaptionCall = styled(CardsContainer)<CheckDisabled>`
  padding: ${({ theme }) => theme.fontSizes['2xl']} 21px;
  background-color: ${(props) =>
    props.isDisabled
      ? ({ theme }) => theme.colors.midnightBlue300
      : ({ theme }) => theme.colors.sessionBackground};
  max-height: 100%;
  position: relative;
  display: flex;
  border-radius: 8px;

  .rsw-editor {
    border: none;
    color: ${({ theme }) => theme.colors.secondaryColorBase};
    overflow: inherit;

    .rsw-cts .ant-input {
      font-size: ${({ fontSize }) => fontSize}px;
      line-height: ${({ lineHeight }) => lineHeight}px;
      font-family: ${({ fontFamily }) => fontFamily};
      color: ${({ theme }) => theme.colors.greenThemeColor};
      font-weight: 600;
    }

    .rsw-cts {
      font-size: ${({ fontSize }) => fontSize}px;
      line-height: ${({ lineHeight }) => lineHeight}px;
      font-family: ${({ fontFamily }) => fontFamily};
      p {
        color: ${({ theme }) => theme.colors.colorText};
      }
      .partial {
        margin-bottom: 0;
        line-height: 1.63;
        display: inline;
      }
      .item {
        margin-bottom: 0;
        line-height: 1.63;
        display: inline;
      }
    }
  }

  .icon-audio-filled,
  .icon-mute-outlined {
    position: sticky;
    font-size: ${({ theme }) => theme.fontSizes['2xl']};
    bottom: 0;
    display: flex;
    align-items: flex-end;
    top: 0;
    color: ${({ theme }) => theme.colors.primaryColorBase};
  }

  & .item.alternative-selection::selection {
    color: ${({ theme }) => theme.colors.alternativesHighlight};
    background: transparent;
    font-family: ${({ theme }) => theme.fonts.inter_semibold};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;

export const ChronoContainer = styled.div`
  display: flex;
  align-items: end;
  margin-top: 48px;
`;
