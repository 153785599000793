import { useFlags } from 'launchdarkly-react-client-sdk';
import NotFound from 'modules/routing/components/not-found/not-found.component';
import { useRoutes } from 'modules/routing/hooks/useRoutes';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { config } from 'shared/config/cts.config';

import AuthRoute from './components/auth/auth-route.component';

const AppRouter = () => {
  const { ctsScreen } = useFlags();
  const featureFlags = React.useMemo(
    () => [
      { name: 'ctsScreen', value: !!config.REACT_APP_IS_CTS && ctsScreen },
    ],
    [ctsScreen]
  );

  const [routes] = useRoutes(featureFlags);
  return (
    <Routes>
      {routes.map((route) =>
        route.path.map((path) => (
          <Route
            key={`${route.key}-${path}`}
            path={path}
            element={<AuthRoute linkedRoute={route} />}
          />
        ))
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default React.memo(AppRouter);
