/* eslint-disable no-console */

import { DateTime } from 'luxon';
import { Custom401 } from 'modules/401/custom-401.component';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import FullScreenLoader from 'shared/components/ui/fullScreenLoader/full-screen-loader';
import useAuthToken from 'shared/datasources/auth/use-auth-token.hook';
import useAuthUser from 'shared/datasources/auth/use-auth-user.hook';
import { useLogin } from 'shared/datasources/auth/use-login.hook';
import { UserStates } from 'shared/hooks/admin/states.enum';
import { useUserStateNoContext } from 'shared/hooks/admin/use-post-update-user-state.hook';
import { useSendDataScienceEventMessage } from 'shared/hooks/data-science-api/use-send-data-science-event-message.hook';
import {
  LogLevelType,
  usePostDynatraceLogsNoContext,
} from 'shared/hooks/dynatrace/use-dynatrace';
import { useAppUser } from 'shared/hooks/use-app-user';
import {
  useStateStorage,
  LocalStorageKeys,
  TokensInfo,
  TokenUser,
} from 'shared/hooks/use-state-storage.hook';

const AGENT_APP_ACCESS = 'Agent App Access';

interface OAuthProviderProps {
  children: React.ReactNode;
}

interface OAuthContextValue {
  tokenUser: TokenUser | null;
  accessToken: string;
  logout: () => void;
}

const OAuthContext = createContext<OAuthContextValue>({
  tokenUser: null,
  accessToken: '',
  logout: () => {},
});

const refreshTime = 30 * 60 * 1000;
const refreshTimeLimit = 9 * 60 * 60 * 1000;

const OAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const handleCallbackCalled = useRef(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tokens, setTokens] = useStateStorage<TokensInfo>(
    LocalStorageKeys.tokens,
    {
      accessToken: '',
      refreshToken: '',
    }
  );
  const [tokenUser, setUser] = useStateStorage<TokenUser | null>(
    LocalStorageKeys.user,
    null
  );
  const [, setInitialAuthTime] = useStateStorage<number | null>(
    LocalStorageKeys.authTime,
    null
  );
  const [loginState, setLoginState] = useStateStorage<string | null>(
    LocalStorageKeys.state,
    null
  );
  const { getUser } = useAuthUser<TokenUser>();
  const { getAppUser } = useAppUser();
  const [getToken, refreshToken] = useAuthToken();
  const { login } = useLogin();
  const { sendSessionLoginEventMessage, sendSessionLogoutEventMessage } =
    useSendDataScienceEventMessage();
  const { log } = usePostDynatraceLogsNoContext();
  const { updateUserState } = useUserStateNoContext();

  const generateState = () => {
    const state = Math.random().toString(36).substring(7);
    localStorage.setItem('state', state);
    setLoginState(state);
    return state;
  };

  const logout = async () => {
    if (tokens.accessToken && tokenUser) {
      await sendSessionLogoutEventMessage(
        tokens.accessToken,
        tokenUser.jti,
        tokenUser.employeeID,
        `${DateTime.now().toISO()}`,
        `${DateTime.now().toISO()}`
      );
    }
    setTokens({
      accessToken: '',
      refreshToken: '',
    });
    setUser(null);
    setInitialAuthTime(null);
    login(generateState());
  };

  async function validateUserAuthorization(
    accessToken: string,
    user: TokenUser
  ) {
    const appUser = await getAppUser(accessToken, user.objectGUID);
    let userHasAccess = false;
    let agentAppAccessPermission;
    let logMessage = '';
    if (!appUser) {
      logMessage = `User not found.`;
    } else if (!appUser.role) {
      logMessage = `User does not have a role`;
    } else if (!appUser.role?.permissions?.length) {
      logMessage = `User role ${appUser.role.name} has no permissions`;
    } else {
      agentAppAccessPermission = appUser.role.permissions.find(
        (permission: any) => permission.code === AGENT_APP_ACCESS
      );
      userHasAccess = !!agentAppAccessPermission;
      logMessage = `User role does not have ${AGENT_APP_ACCESS}`;
    }
    if (!userHasAccess) {
      await log(accessToken, {
        logLevelType: LogLevelType.WARN,
        methodName: 'validateUserAuthorization',
        agentNumber: user?.employeeID,
        parameters: {
          accessToken: !accessToken ? accessToken : 'has value',
          user: {
            objectGUID: user?.objectGUID,
            username: user?.sub,
            employeeID: user?.employeeID,
            role: appUser?.role?.name,
          },
        },
        message: logMessage,
      });
      return null;
    }
    return appUser;
  }

  const handleCallback = async (code: string, state: string) => {
    if (state !== loginState && !window.electronAPI) {
      console.error('Invalid state');
      logout();
      return;
    }
    try {
      const response = await getToken(code);
      const { access_token: accessToken, refresh_token: refreshToken } =
        response.data;
      setTokens({
        accessToken,
        refreshToken,
      });
      setInitialAuthTime(new Date().getTime());
      const userRespone = await getUser(accessToken);
      const user = userRespone?.data;
      const appUser = await validateUserAuthorization(accessToken, user);
      if (!appUser) {
        setIsLoading(false);
        return;
      }
      user.userId = appUser.userId;
      user.agentNumber = appUser.agentNumber;
      user.agentEmail = appUser.agentEmail;
      user.isActive = appUser.isActive;
      user.no911Calls = appUser.no911Calls;
      user.creationDt = appUser.creationDt;
      user.modifiedDt = appUser.modifiedDt;
      user.modifiedBy = appUser.modifiedBy;
      user.language = appUser.language;
      user.role = appUser.role;
      user.callCenters = appUser.callCenters;
      const homeCallCenter = tokenUser?.callCenters.find(
        (callCenter) => callCenter.isHomeCallCenter
      );
      if (homeCallCenter) {
        user.callCenterId = homeCallCenter.name;
      }
      setUser(user);

      await updateUserState(accessToken, {
        stateName: UserStates.IN_LOBBY,
        sessionId: user.jti,
      });
      await sendSessionLoginEventMessage(
        accessToken,
        user.jti,
        `${DateTime.now().toISO()}`,
        user.language,
        user.employeeID,
        '', // callCenterId
        user.callCenterId,
        '', // callCenterId
        user.callCenterId
      );
      await log(accessToken, {
        logLevelType: LogLevelType.INFO,
        agentNumber: user?.employeeID,
        message: `User ${user.agentEmail} logged in successfully`,
      });
      window.location.href = window.location.origin;
    } catch (error) {
      console.error(error);
      if (!tokens.accessToken) {
        // getToken must have failed,
        // possibly due to an obsolete 'code'
        logout();
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (tokens.accessToken) {
      setIsLoading(false);
      return;
    }
    if (!urlParams.get('code')) {
      login(generateState());
    } else if (!handleCallbackCalled.current) {
      handleCallback(urlParams.get('code')!, urlParams.get('state')!);
      handleCallbackCalled.current = true;
    }
  }, []);

  const refreshAccessToken = async () => {
    if (!tokens.refreshToken) {
      return;
    }

    try {
      const response = await refreshToken(tokens.refreshToken);
      const { access_token: accessToken } = response.data;
      setTokens((prevState) => ({
        ...prevState,
        accessToken,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!tokens.refreshToken) {
      // If we don't have a refreshToken then we can't do anything here
      return () => {};
    }
    const storedTime = localStorage.getItem('time');
    localStorage.setItem('loginTime', Date.now().toString());
    if (storedTime) {
      const timeRemaining = Math.max(
        0,
        refreshTime - (Date.now() - parseInt(storedTime, 10))
      );
      const intervalId = setInterval(async () => {
        console.log('timeRemaining: ', timeRemaining);
        await refreshAccessToken();
        localStorage.setItem('time', Date.now().toString());
      }, timeRemaining);
      return () => clearInterval(intervalId);
    } else {
      const intervalId = setInterval(async () => {
        console.log('refreshing: ');
        await refreshAccessToken();
        localStorage.setItem('time', Date.now().toString());
      }, refreshTime);
      return () => clearInterval(intervalId);
    }
  }, []);

  const checkRefreshExpirationTime = () => {
    const loginTime = localStorage.getItem('loginTime');
    if (loginTime) {
      const loginTimeDiff = Date.now() - parseInt(loginTime, 10);
      if (loginTimeDiff >= refreshTimeLimit) {
        localStorage.removeItem('loginTime');
        logout();
      }
    }
  };

  useEffect(() => {
    const refreshExpirationCheckInterval = setInterval(() => {
      checkRefreshExpirationTime();
    }, 60 * 1000);
    return () => clearInterval(refreshExpirationCheckInterval);
  }, []);

  (window as any).refreshSession = () => refreshAccessToken();
  (window as any).logout = () => logout();

  return (
    <OAuthContext.Provider
      value={{
        tokenUser,
        logout,
        accessToken: tokens.accessToken,
      }}
    >
      {tokenUser && <>{children}</>}
      {!tokenUser && !isLoading && <Custom401 onLogout={logout} />}
      {!tokenUser && isLoading && <FullScreenLoader />}
    </OAuthContext.Provider>
  );
};

const useOAuth = () => {
  const auth = useContext(OAuthContext);
  if (!auth) {
    throw new Error('useOAuth must be used within an OAuthProvider');
  }
  return auth;
};

export { OAuthProvider, useOAuth };
