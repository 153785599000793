import CallDispositionsMenu from 'modules/ipcts-call-session/components/controls/call-dispositions-menu.component';
import CallHandoff from 'modules/ipcts-call-session/components/controls/call-handoff.component';
import { useChronometer } from 'modules/ipcts-call-session/hooks/use-chronometer.hook';
import * as React from 'react';

import {
  ESilentCallDisposition,
  ETerminationReasonCode,
} from 'shared/datasources/call-detail-record-service/entities.dto';

import * as Styled from './call-controls.styled';

const HANDOFF_MENU = { ID: 'HANDOFF_MENU' };
const DISPOSITIONS_MENU = { ID: 'DISPOSITIONS_MENU' };
interface ICallControlsProps {
  dispositionCallEnd: () => void;
  handoffCallEnd: () => void;
  addCustomCaption: (text: string, editable?: boolean) => void;
  dispositionSelected: ETerminationReasonCode;
  setDispositionSelected: React.Dispatch<
    React.SetStateAction<ETerminationReasonCode>
  >;
  setSilentDispositionSelected: React.Dispatch<
    React.SetStateAction<ESilentCallDisposition>
  >;
}

const CallControls = ({
  dispositionCallEnd,
  handoffCallEnd,
  addCustomCaption,
  dispositionSelected,
  setDispositionSelected,
  setSilentDispositionSelected,
}: ICallControlsProps) => {
  const [handoffMenuSelected, setHandoffMenuSelected] = React.useState(false);
  const [dispositionsMenuSelected, setDispositionsMenuSelected] =
    React.useState(true);
  const [tabsHidden, setTabsHidden] = React.useState(false);
  const { counter } = useChronometer(true);
  const handleMenuSelected = (selected: string) => {
    const flows = {
      [HANDOFF_MENU.ID]: () => {
        if (!handoffMenuSelected) {
          setHandoffMenuSelected(true);
          setDispositionsMenuSelected(false);
        } else {
          setHandoffMenuSelected(false);
          setDispositionsMenuSelected(true);
        }
      },
      [DISPOSITIONS_MENU.ID]: () => {
        if (!dispositionsMenuSelected) {
          setDispositionsMenuSelected(true);
          setHandoffMenuSelected(false);
        } else {
          setDispositionsMenuSelected(false);
          setHandoffMenuSelected(true);
        }
      },
    };
    const result = flows[selected];
    if (!result) {
      return;
    }
    result();
  };

  const selectedMenu = () => {
    if (dispositionsMenuSelected) {
      return (
        <CallDispositionsMenu
          dispositionCallEnd={dispositionCallEnd}
          addCustomCaption={addCustomCaption}
          dispositionSelected={dispositionSelected}
          setDispositionSelected={setDispositionSelected}
          setSilentDispositionSelected={setSilentDispositionSelected}
        />
      );
    } else {
      return (
        <CallHandoff
          addCustomCaption={addCustomCaption}
          chronometerCounter={counter}
          handoffCallEnd={handoffCallEnd}
          tabsHidden={tabsHidden}
          setTabsHidden={setTabsHidden}
        />
      );
    }
  };

  return (
    <Styled.CallControlsContainer>
      <Styled.TabButtonsContainer hidden={tabsHidden}>
        <Styled.TabButton
          id="dispositions-tab"
          selected={dispositionsMenuSelected}
          disabled={dispositionsMenuSelected}
          onClick={() => handleMenuSelected(DISPOSITIONS_MENU.ID)}
        >
          Call Disposition
        </Styled.TabButton>
        <Styled.TabButton
          id="handoff-tab"
          selected={handoffMenuSelected}
          disabled={handoffMenuSelected}
          onClick={() => handleMenuSelected(HANDOFF_MENU.ID)}
        >
          Handoff
        </Styled.TabButton>
      </Styled.TabButtonsContainer>
      {selectedMenu()}
    </Styled.CallControlsContainer>
  );
};

export default CallControls;
