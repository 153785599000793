import * as React from 'react';
import { darktheme } from 'shared/styles/theme';

type ThemeContextType = {
  accent: any;
  setAccent: React.Dispatch<React.SetStateAction<any>>;
  handleThemeChange: (theme: any) => void;
};

const ThemeContext = React.createContext({} as any as ThemeContextType);

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [accent, setAccent] = React.useState(darktheme);

  const handleThemeChange = (theme: any) => {
    setAccent(theme);
    sessionStorage.setItem('currentTheme', theme.name.id);
  };

  return (
    <ThemeContext.Provider value={{ accent, setAccent, handleThemeChange }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
