import Background from 'assets/icons/background.svg';
import * as Styled from 'modules/download/download.style';
import { useEffect, useState } from 'react';

export const Download = () => {
  const environmentHosts = {
    'dev-cts': {
      bucket: 'dev-installer-bucket',
      appName: 'CTS-Web-Dev',
      adminAppName: 'captioner_admin_dev'
    },
    'qa-cts':{
      bucket:  'qa-installer-bucket',
      appName: 'CTS-Web-Qa',
      adminAppName: 'captioner_admin_qa'
    },
    'cts': {
      bucket: 'production-installer-bucket',
      appName: 'CTS-Web',
      adminAppName: 'captioner_admin'
    }
  }

  const isWindows = navigator?.userAgent.includes('Windows')
  let os = '';
  let osFolderName = '';
  let osExtension = '';
  if(isWindows){
    os = 'Windows';
    osFolderName = 'WIN';
    osExtension = '.exe';
  } else {
    os = 'MacOS';
    osFolderName = 'MAC';
    osExtension = '.dmg';
  }

  const host = location.host
  const subdomain = host.split('.')[0]
  const isSubDomainInEnvironmentHosts = subdomain in environmentHosts
  let captionerHref = '';
  let adminHref = '';
  if(isSubDomainInEnvironmentHosts){
    captionerHref = `https://${environmentHosts[subdomain].bucket}.s3.us-west-2.amazonaws.com/CAPTIONER/${osFolderName}/${ environmentHosts[subdomain].appName  }${isWindows ? '.exe' : '.dmg'}`
    adminHref = `https://${environmentHosts[subdomain].bucket}.s3.us-west-2.amazonaws.com/ADMIN/${osFolderName}/${environmentHosts[subdomain].adminAppName}${isWindows ? '.exe' : '.dmg'}`
  }else{
    captionerHref = `https://${environmentHosts.cts.bucket}.s3.us-west-2.amazonaws.com/CAPTIONER/${osFolderName}/${environmentHosts.cts.appName}${isWindows ? '.exe' : '.dmg'}`
    adminHref = `https://${environmentHosts.cts.bucket}.s3.us-west-2.amazonaws.com/ADMIN/${osFolderName}/${environmentHosts.cts.adminAppName}${isWindows ? '.exe' : '.dmg'}`
  }

  return (
    <>
      <Styled.BackgroundContainer>
        <Styled.Background src={Background}> </Styled.Background>
        <Styled.MainDiv>
          <Styled.Title>
            <h5>IP Captioned Telephone Service</h5>
            <img src='icons/sorenson-logo-152x152.png' width={80}/>
          </Styled.Title>
        </Styled.MainDiv>
        <Styled.ProductSection>
          <Styled.ButtonContainer>
            <Styled.Button href={captionerHref} >Download Captioner for {os}</Styled.Button>
          </Styled.ButtonContainer>
          <Styled.ButtonContainer>
            <Styled.Button href={adminHref} >Download Admin for {os}</Styled.Button>
          </Styled.ButtonContainer>
        </Styled.ProductSection>
      </Styled.BackgroundContainer>
    </>
  )
}