import { HubConnectionBuilder } from '@microsoft/signalr';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { openErrorNotification } from 'shared/components/ui/alerts/notification.component';
import { config } from 'shared/config/cts.config';
import { NotificationService } from 'shared/datasources/agent-service/entities.dto';
import { demoLog } from 'shared/utils/demo-log';

const KEEP_ALIVE = 2000;
const TIMEOUT = 4000;

// This endpoint is not being used currently as it is part of Modern Relay.
// Will be kept for reference purposes.
export const useNotificationsService = () => {
  const { getUserToken } = useContext(AuthenticationContext);
  const agentConnection = useRef(
    new HubConnectionBuilder()
      .configureLogging('none')
      .withUrl(`${config.REACT_APP_HUB_URL}`, {
        accessTokenFactory: () => getUserToken()!,
      })
      .withAutomaticReconnect()
      .build()
  );

  agentConnection.current.keepAliveIntervalInMilliseconds = KEEP_ALIVE;
  agentConnection.current.serverTimeoutInMilliseconds = TIMEOUT;

  const [currentNotificationId, setCurrentNotificationId] = useState<string>();
  const [agentInfo, setAgentInfo] = useState<NotificationService>();
  const [readyForCall, setReadyForCall] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [callId, setCallId] = useState('');
  const { setShowQueue } = useContext(LandingPageContext);

  const handleMessage = useCallback(
    (_: any, connectionID: string) => {
      demoLog(`CONNECTED TO NOTIFICATION SERVICE ID: ${connectionID}`);
      setCurrentNotificationId(connectionID);

      if (agentInfo) {
        const updatedAgent: NotificationService = {
          ...agentInfo,
          NotificationId: connectionID,
        };

        agentConnection.current
          .invoke('SupplyAgentInfo', JSON.stringify(updatedAgent))
          .catch(demoLog);
      }
    },
    [setCurrentNotificationId, agentInfo]
  );

  const handleIncomingCall = useCallback(
    async (holdServerId: string, callId: string) => {
      demoLog('INCOMING CALL EVENT RECEIVED', `callId:${callId}}`);
      if (readyForCall) {
        setCallId(callId);
        demoLog('AGENT IS READY FOR CALL EVENT SENT');
        await agentConnection.current.invoke(
          'AgentIsReady',
          holdServerId,
          callId
        );
      }
    },
    [readyForCall]
  );

  const handleAgentIsReady = (agentConnId: string, callId: string) => {
    demoLog('EVENT AGENTISREADY RECEIVED');
    demoLog('agentConnId', agentConnId);
    demoLog('callId', callId);
  };

  const startConnection = useCallback(async () => {
    await agentConnection.current.start().then(() => {
      demoLog('CONNECTION TO NOTIFICATION SERVICE STARTED');
    });
  }, []);

  useEffect(() => {
    agentConnection.current.off('message');
    agentConnection.current.off('IncomingCall');
    agentConnection.current.off('AgentIsReady');
    agentConnection.current.on('message', handleMessage);
    agentConnection.current.on('IncomingCall', handleIncomingCall);
    agentConnection.current.on('AgentIsReady', handleAgentIsReady);
  }, [handleMessage, handleIncomingCall, handleAgentIsReady]);

  useEffect(() => {
    if (agentInfo) {
      demoLog('ABOUT TO START CONNECTION WITH NOTIFICATION SERVICE');
      startConnection().catch(() => {
        setShowQueue(false);
        openErrorNotification(
          'topRight',
          'An error occured while establishing connection with notification service.',
          'Error'
        );
      });
    }
  }, [agentInfo]);

  const supplyAgentInfo = useCallback(
    async (agent: NotificationService) => {
      setAgentInfo(agent);
      demoLog('SUPPLYING AGENT INFO TO NOTIFICATION SERVICE');
    },
    [currentNotificationId]
  );

  return {
    currentNotificationId,
    supplyAgentInfo,
    readyForCall,
    setReadyForCall,
    isCallActive,
    setIsCallActive,
    callId,
    setCallId,
  };
};
