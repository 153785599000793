import { landingPageModule } from 'modules/landing-page/landing-page.module';
import { IRouteModule } from 'modules/routing/routes.types';
import { downloadPageModule } from 'modules/download/download.module';
import { config } from 'shared/config/cts.config';

const routesArray: IRouteModule[] = [];
const downloadRoute: IRouteModule = downloadPageModule();

routesArray.push(landingPageModule());
routesArray.push(downloadRoute);

const RoutesModule = (): IRouteModule[] => routesArray;
export default RoutesModule;
