import NORMAL from 'assets/icons/normal_icon.svg';
import OTHER from 'assets/icons/other_icon.svg';
import SILENT from 'assets/icons/silent_icon.svg';
import { handleDispositionButtonAction } from 'modules/ipcts-call-session/components/controls/utils/handle-disposition.util';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import * as React from 'react';
import * as Styled from './call-dispositions-menu.styled';

enum EThreePartyModeDisposition {
  Normal = 'Normal',
  AnsweringMachine = 'AnsweringMachine',
  SilentAnsweringMachine = 'SilentAnsweringMachine',
  Silent = 'Silent',
  Other = 'Other',
}

enum EOptions {
  Normal = 'normal',
  AnsweringMachine = 'answering_machine',
  SilentAnsweringMachine = 'silent_answering_machine',
  Silent_ClearEnding = 'clear_ending',
  Silent_CompletelySilent = 'completely_silent',
  Silent_MidCall = 'mid_call',
  Other_FaxTone = 'fax_tone',
  Other_UnsupportedLanguage = 'unsupported_language',
  Other_Illegitimate = 'illegitimate',
}

interface ICallDispositionsThreePartyMode {
  setTimerSeconds: React.Dispatch<React.SetStateAction<number>>;
  setShowTimer: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThreePartyMode = ({
  setTimerSeconds,
  setShowTimer,
}: ICallDispositionsThreePartyMode) => {
  const { addCustomCaption } = React.useContext(IPCTSSessionContext);

  const [isSilentOpen, setIsSilentOpen] = React.useState(false);
  const [isOtherOpen, setIsOtherOpen] = React.useState(false);

  const handleDispositionAction = (disposition: string) => {
    switch (disposition) {
      case EOptions.Normal:
        break;
      case EOptions.AnsweringMachine:
        break;
      case EOptions.SilentAnsweringMachine:
        setTimerSeconds(10);
        setShowTimer(true);
        break;
      case EOptions.Silent_ClearEnding:
        addCustomCaption('(Silence)');
        setTimerSeconds(60);
        setShowTimer(true);
        break;
      case EOptions.Silent_CompletelySilent:
        break;
      case EOptions.Silent_MidCall:
        break;
      case EOptions.Other_FaxTone:
        break;
      case EOptions.Other_UnsupportedLanguage:
        break;
      case EOptions.Other_Illegitimate:
        break;
      default:
        break;
    }
  };

  const handleDropdownOptions = (
    event: React.MouseEvent<HTMLElement>,
    disposition: EThreePartyModeDisposition
  ) => {
    const flows = {
      [EThreePartyModeDisposition.Normal]: () => {
        handleDispositionAction(EOptions.Normal);
      },
      [EThreePartyModeDisposition.AnsweringMachine]: () => {
        handleDispositionAction(EOptions.AnsweringMachine);
      },
      [EThreePartyModeDisposition.SilentAnsweringMachine]: () => {
        handleDispositionAction(EOptions.SilentAnsweringMachine);
      },
      [EThreePartyModeDisposition.Silent]: () => {
        const select = document
          .querySelectorAll('.ant-select-selector')
          .item(0);
        const mouseDownEvent = new Event('mousedown', {
          bubbles: true,
          cancelable: false,
        });
        if (select) select.dispatchEvent(mouseDownEvent);
        setIsSilentOpen(!isSilentOpen);
        setIsOtherOpen(false);

        const selectTitle = document
          .querySelectorAll('.ant-select-selection-item')
          .item(0);
        if (selectTitle) {
          selectTitle.setAttribute(
            'class',
            'ant-select-selection-item silent-select'
          );
        }
      },
      [EThreePartyModeDisposition.Other]: () => {
        const select = document
          .querySelectorAll('.ant-select-selector')
          .item(1);
        const mouseDownEvent = new Event('mousedown', {
          bubbles: true,
          cancelable: false,
        });
        if (select) select.dispatchEvent(mouseDownEvent);
        setIsSilentOpen(false);
        setIsOtherOpen(!isOtherOpen);
      },
    };
    const result = flows[disposition];
    if (!result) {
      return;
    }
    result();
    handleDispositionButtonAction(disposition);
  };

  return (
    <Styled.DispositionButtonsContainer>
      <style>
        {`
            .ant-select-dropdown {
              background-color: ${
                sessionStorage.getItem('currentTheme') === 'light'
                  ? '#080F13'
                  : 'green'
              };
              width: 323px !important;
              border: 1px solid #2E3437;
            }

            .ant-select-item-option-content {
              color: #E3E6E6 !important;
            }
            .ant-select-item-option-selected{
              background-color: #061E20 !important;
            }

            .ant-select-item-option-active {
              background-color: #061E20 !important;
            }

            .silent-select span {
              color: ${isSilentOpen ? '#E5E6E6' : '#E5E6E6'} !important;
            }
            .other-select span {
              color: ${isOtherOpen ? '#E5E6E6' : '#E5E6E6'} !important;
            }
          `}
      </style>
      <Styled.DispositionOptionButton
        id={EThreePartyModeDisposition.Normal}
        onClick={(event) =>
          handleDropdownOptions(event, EThreePartyModeDisposition.Normal)
        }
      >
        <Styled.SVGIcon src={NORMAL} />
        <Styled.ButtonText>Normal</Styled.ButtonText>
      </Styled.DispositionOptionButton>

      <Styled.DispositionOptionButton
        id={EThreePartyModeDisposition.AnsweringMachine}
        onClick={(event) =>
          handleDropdownOptions(
            event,
            EThreePartyModeDisposition.AnsweringMachine
          )
        }
      >
        <Styled.SVGIcon src={NORMAL} />
        <Styled.ButtonText>Answering Machine</Styled.ButtonText>
      </Styled.DispositionOptionButton>

      <Styled.DispositionOptionButton
        id={EThreePartyModeDisposition.SilentAnsweringMachine}
        onClick={(event) =>
          handleDropdownOptions(
            event,
            EThreePartyModeDisposition.SilentAnsweringMachine
          )
        }
      >
        <Styled.SVGIcon src={NORMAL} />
        <Styled.ButtonText>Silent Answering Machine</Styled.ButtonText>
      </Styled.DispositionOptionButton>

      <Styled.DispositionOptionButton
        id={EThreePartyModeDisposition.Silent}
        onClick={(event) =>
          handleDropdownOptions(event, EThreePartyModeDisposition.Silent)
        }
      >
        <Styled.SilentIconSVG src={SILENT} isOpen={isSilentOpen} />
        <Styled.SelectDropDown
          bordered={false}
          className="silent-select"
          defaultValue="silent"
          dropdownAlign={{ offset: [-56, 12] }}
          style={{ width: 323 }}
          onChange={(value: unknown) => {
            handleDispositionAction(value as string);
          }}
          onClick={(event) => {
            setIsSilentOpen(!isSilentOpen);
            setIsOtherOpen(false);
            event.stopPropagation();
          }}
          open={isSilentOpen}
          options={[
            {
              value: 'silent',
              label: 'Silent',
              hidden: true,
            },
            {
              value: EOptions.Silent_ClearEnding,
              label: 'Clear Ending',
            },
            {
              value: EOptions.Silent_CompletelySilent,
              label: 'Completely Silent',
            },
            {
              value: EOptions.Silent_MidCall,
              label: 'Mid-Call',
            },
          ]}
        />
      </Styled.DispositionOptionButton>
      <Styled.DispositionOptionButton
        id={EThreePartyModeDisposition.Other}
        onClick={(event) =>
          handleDropdownOptions(event, EThreePartyModeDisposition.Other)
        }
      >
        <Styled.OtherIconSVG src={OTHER} isOpen={isOtherOpen} />
        <Styled.SelectDropDown
          bordered={false}
          className="other-select"
          defaultValue="other"
          dropdownAlign={{ offset: [-56, 12] }}
          style={{ width: 323 }}
          onChange={(value: unknown) => {
            handleDispositionAction(value as string);
          }}
          onClick={(event) => {
            setIsSilentOpen(false);
            setIsOtherOpen(!isOtherOpen);
            event.stopPropagation();
          }}
          open={isOtherOpen}
          options={[
            {
              value: 'other',
              label: 'Other',
              hidden: true,
            },
            {
              value: EOptions.Other_FaxTone,
              label: 'Fax Tone',
            },
            {
              value: EOptions.Other_UnsupportedLanguage,
              label: 'Unsupported Language',
            },
            {
              value: EOptions.Other_Illegitimate,
              label: 'Illegitimate',
            },
          ]}
        />
      </Styled.DispositionOptionButton>
    </Styled.DispositionButtonsContainer>
  );
};

export default ThreePartyMode;
