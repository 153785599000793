import { GlobalNotificationStyles } from 'shared/components/ui/alerts/notification-messages.styled';
import { PopconfirmStyles } from 'shared/components/ui/popconfirm/popconfirm.component.styled';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: ${(props) => props.theme.colors.background};
    font-family: ${(props) => props.theme.fonts.inter_regular};
    font-size: ${(props) => props.theme.fontSizes.md};
    color: ${(props) => props.theme.colors.textColorBase};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-notification-topRight {
    margin-right: 30%; 
  }
  
  .ant-popover-inner {
    background-color: #050e15 !important;
  }

  .ant-popover-inner-content{
    background-color: #050e15;
    padding: 0px;
  }

  .ant-slider-handle {
  inset-block-start: auto !important;
  }

  .pop-up-input .ant-input, .no-alternatives{
    color: #4e5558 !important;
    font-family: ${(props) => props.theme.fonts.inter_semibold} !important;
    font-size: ${(props) => props.theme.fontSizes.md} !important;
  }

  .custom-class {
    background-color: rgba(255, 105, 92, 0.21);
    border: 1px solid #ff695c;

  .ant-notification-notice-message,
  .ant-notification-notice-description {
    color: #ffffff;
    font-size: 14px;
  }

  .ant-notification-close-x{
      span{
        svg{
          path{
                fill: #ff695c;
          }
        }
      }
    }

 .ant-notification-notice-content{
  .ant-notification-notice-with-icon{
    .ant-notification-notice-icon{
      span{
        svg{
          path{
                fill: #ff695c;
          }
        }
      }
    }
  }
  }
}

.network-error-notification {
  background-color: rgba(255, 105, 92, 0.99);
    border: 1px solid #ff695c;

  .ant-notification-notice-message,
  .ant-notification-notice-description {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }

  .ant-alert-close-icon {
    color: #fff;
  }
}

  // theme buttons color
.light {
  background-color: hsl(0, 0%, 93%);
}
.dark {
  background-color: hsl(0, 0%, 20%);
}
.blue {
  background-color: hsl(195, 53%, 79%);
}
.green {
  background-color: hsl(150, 80%, 15%);
}
.brown {
  background-color: hsl(39, 70%, 50%);
}
.pink {
  background-color: hsl(350, 100%, 88%);
}

  .ant-layout {
    background-color: ${(props) => props.theme.colors.white};
    min-height: calc(100vh - 148px);
    padding-bottom: 0;
  }

  .ant-collapse-content-box{
    padding: 0 !important;
  }

  h1 {
    font-family: ${(props) => props.theme.fonts.inter_regular};
    font-size: ${(props) => props.theme.fontSizes['4xl']};
  }

  h2 {
    font-family: ${(props) => props.theme.fonts.inter_regular};
    font-size: ${(props) => props.theme.fontSizes['3xl']};
  }

  h3 {
    font-family: ${(props) => props.theme.fonts.inter_regular};
    font-size: ${(props) => props.theme.fontSizes['2xl']};
  }

  h4 {
    font-family: ${(props) => props.theme.fonts.inter_regular};
    font-size: ${(props) => props.theme.fontSizes.xl};
  }

  h5 {
    font-family: ${(props) => props.theme.fonts.inter_regular};
    font-size: ${(props) => props.theme.fontSizes.lg};
  }

  h6 {
    font-family: ${(props) => props.theme.fonts.inter_regular};
    font-size: ${(props) => props.theme.fontSizes.md};
  }

  a {
    color: ${({ theme }) => theme.colors.deepSkyBlue};
  }

  a:hover {
    color: ${({ theme }) =>
      theme.colors.primaryColorBaseRGBA?.replace('{opacity}', '0.8')};
  }

  .just-mobile {
    display: block;

    @media (min-width: ${({ theme }) => theme.breakPoints['2xl']}) {
      display: none;
    }
  }

  .just-desktop {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakPoints['2xl']}) {
      display: flex;
    }
  }
  .ant-divider {
    border-top: 1px solid ${({ theme }) => theme.colors.inputsBorder};
  }
    
  ${PopconfirmStyles}


  #notifications {
    background-color: transparent;
  }

  #notifications .ant-popover-inner-content {
    padding: 0px;
  }


  .ant-empty-description {
    color: #b7b4b4 !important;
  }
  
  .ant-input-affix-wrapper:hover {
    border-color: #232323;
  }

  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
    border-color: ${({ theme }) => theme.colors.deepSkyBlue};
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-picker-cell-range-start .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme.colors.activeState} !important;
    color: ${({ theme }) => theme.colors.primaryColorBase} !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
    background-color: ${({ theme }) =>
      theme.colors.activeStateRGBA?.replace('{opacity}', '0.5')} !important;
  }
  .ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme.colors.activeState} !important;
    color: ${({ theme }) => theme.colors.primaryColorBase} !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background-color: ${({ theme }) =>
      theme.colors.activeStateRGBA?.replace('{opacity}', '0.5')} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1.5px solid ${({ theme }) => theme.colors.activeState} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: ${({ theme }) =>
      theme.colors.activeStateRGBA?.replace('{opacity}', '0.5')} !important;
  }
  .ant-picker-cell-in-range.ant-picker-cell-inner {
    background-color: ${({ theme }) =>
      theme.colors.activeStateRGBA?.replace('{opacity}', '0.5')} !important;
  }

  ${GlobalNotificationStyles}

  .ant-picker-panels {
    flex-direction: column;
  }

  .vertical-default-scroll {
    font-size: ${({ theme }) => theme.fontSizes.md};
    overflow-y: scroll;
    scrollbar-color: rgba(255, 255, 255, 0.6) transparent;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 12px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${({ theme }) => theme.colors.porpoiseFin};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.lg}) {
    .ant-picker-panels {
      flex-direction: row;
    }
  }
`;

export default GlobalStyle;
