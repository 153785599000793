import BottomControls from 'modules/ipcts-call-session/components/bottom-controls/bottom-controls.component';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { NetworkStatus } from 'modules/ipcts-call-session/pfc-screen/components/network-status.component';
import PfcSettings from 'modules/ipcts-call-session/pfc-screen/components/pfc-settings.component';
import * as Styled from 'modules/ipcts-call-session/pfc-screen/preflight-check.styled';
import React from 'react';
import {
  ContentEditableEvent,
  Editor,
  EditorProvider,
} from 'shared/components/ui/editor';
import { CaptionShard, GateWaySockets } from 'shared/hooks/axon/gateway.types';

interface PFCProps {
  setShowQueue: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPFC: React.Dispatch<React.SetStateAction<boolean>>;
  text: CaptionShard[];
  handleChange: (event: ContentEditableEvent) => void;
  handleStartListening: (socket: GateWaySockets) => void;
  handleStopListening: (socket: GateWaySockets) => void;
  cardCaptionCallRef: React.MutableRefObject<HTMLDivElement | null>;
  setIsSessionScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreflightChekScreen = ({
  setShowQueue,
  setShowPFC,
  setIsSessionScreen,
  text,
  handleChange,
  handleStartListening,
  handleStopListening,
  cardCaptionCallRef,
}: PFCProps) => {
  const textArea = React.useRef<HTMLElement>();

  const {
    captionAreaFontSize,
    captionAreaLineHeight,
    captionAreaFontFamily,
    reset,
  } = React.useContext(IPCTSSessionContext);

  const handdleStartButton = () => {
    handleStopListening(GateWaySockets.PFCSocket);
    reset();
    setShowPFC(false);
    setShowQueue(true);
    setIsSessionScreen(true);
  };

  const handleTestCallButton = () => {
    document.querySelector('#startTestButton')!.setAttribute('hidden', 'true');
    handleStartListening(GateWaySockets.PFCSocket);
  };

  const handleClosePFCConnectionButton = () => {
    document.querySelector('#startTestButton')!.removeAttribute('hidden');
    handleStopListening(GateWaySockets.PFCSocket);
  };

  //For testing purposes
  (window as any).endPFC = () => {
    handleClosePFCConnectionButton();
  };

  return (
    <Styled.PFCPageContainer>
      <Styled.LeftContainer>
        <Styled.CardCaptionCall
          ref={cardCaptionCallRef}
          className="vertical-default-scroll"
          isDisabled={false}
          fontSize={captionAreaFontSize}
          lineHeight={captionAreaLineHeight}
          fontFamily={captionAreaFontFamily}
        >
          <div className="editor-provider-wrapper">
            <EditorProvider>
              <Editor
                id="textWindow"
                value={text}
                onChange={handleChange}
                disabled={false}
                editorRef={textArea}
                isCTS
              />
            </EditorProvider>
          </div>
        </Styled.CardCaptionCall>
        <BottomControls />
      </Styled.LeftContainer>
      <Styled.RightContainer>
        <PfcSettings />
        <Styled.ButtonContainer>
          <div>
            <NetworkStatus />
          </div>
          <Styled.StartButton id="nextButton" onClick={handdleStartButton}>
            Start taking call
          </Styled.StartButton>
        </Styled.ButtonContainer>
      </Styled.RightContainer>
    </Styled.PFCPageContainer>
  );
};

export default PreflightChekScreen;
