import * as React from 'react';

import RoutesModule from '../routes.module';
import { IRouteModule } from '../routes.types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { config } from 'shared/config/cts.config';

type RouteModuleContextType = [IRouteModule[]];

const RouteModuleContext = React.createContext(
  [] as any as RouteModuleContextType
);

const HOME_ROUTE = 'Home';
const DOWNLOAD_ROUTE = 'DownloadPage';

const RouteModuleProvider = ({ children }: { children: React.ReactNode }) => {
  const { useElectronQa } = useFlags();
  const routeModules = React.useMemo<IRouteModule[]>(() => RoutesModule(), []);

  const DOWNLOAD_PAGE_CONDITION =
    config.BUILD_TYPE === 'production' &&
    !window?.electronAPI &&
    !useElectronQa;

  const filteredRoutes = DOWNLOAD_PAGE_CONDITION
    ? routeModules.filter(({ name }) => name !== HOME_ROUTE)
    : routeModules.filter(({ name }) => name !== DOWNLOAD_ROUTE);

  return (
    <RouteModuleContext.Provider value={[filteredRoutes]}>
      {children}
    </RouteModuleContext.Provider>
  );
};

export { RouteModuleContext, RouteModuleProvider };
